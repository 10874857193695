import React from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"

const PageTemplate = ({ data: { page } }) => {



  const { title, meta, pageBlocks } = page || {}
  return (
    <>
      {title && <h1>{title}</h1>}
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`
